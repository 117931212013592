import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import Welcome from "../views/Welcome.vue";
import Recommendation from "../views/Recommendation.vue";

Vue.use(VueRouter);
Vue.use(RouterPrefetch);

const routes = [
  {
    path: "/",
    redirect: "/welcome"
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome
  },
  {
    path: "/hosting-the-world",    
    redirect: "/hosting-the-world/our-la",    
  },
  {
    path: "/champion-la",    
    redirect: "/champion-la/why-champion-la",    
  },
  {
    path: "/your-opportunity",    
    redirect: "/your-opportunity/packages",
  },
  {
    path: "/hosting-the-world/our-la",
    name: "Our LA",
    component: () => import(/* webpackChunkName: "Hosting" */ '../views/OurLA.vue')
  },
  {
    path: "/hosting-the-world/events",
    name: "Events",
    component: () => import(/* webpackChunkName: "Hosting" */ '../views/Events.vue')
  },


  {
    path: "/champion-la/why-champion-la",
    name: "Why ChampionLA?",
    component: () => import(/* webpackChunkName: "ChampionLA" */ '../views/WhyChampionLA.vue')
  },
  {
    path: "/champion-la/roi-on-events",
    name: "ROI on Events",
    component: () => import(/* webpackChunkName: "ChampionLA" */ '../views/ROI.vue')
  },
  {
    path: "/champion-la/use-of-funds",
    name: "Use of Funds",
    component: () => import(/* webpackChunkName: "ChampionLA" */ '../views/UseOfFunds.vue')
  },
  {
    path: "/champion-la/who-is-champion-la",
    name: "Who is ChampionLA?",
    component: () => import(/* webpackChunkName: "ChampionLA" */ '../views/WhoIsChampionLA.vue')
  },


  {
    path: "/your-opportunity/packages",
    name: "Packages",
    component: () => import(/* webpackChunkName: "YourOpportunity" */ '../views/Packages.vue')
  },
  {
    path: "/your-opportunity/packages/leaders-circle",
    name: "Leaders Circle",
    component: () => import(/* webpackChunkName: "YourOpportunity" */ '../views/LeadersCircle.vue')
  },
  {
    path: "/your-opportunity/packages/premier-circle",
    name: "Premier Circle",
    component: () => import(/* webpackChunkName: "YourOpportunity" */ '../views/PremierCircle.vue')
  },
  {
    path: "/your-opportunity/above-and-beyond",
    name: "Above & Beyond",
    component: () => import(/* webpackChunkName: "YourOpportunity" */ '../views/AboveAndBeyond.vue')
  },
  {
    path: "/your-opportunity/contribution",
    name: "Contribution",
    component: () => import(/* webpackChunkName: "YourOpportunity" */ '../views/Contribution.vue')
  },
  {
    path: "/:purl",
    name: "Your Package Recommendation",
    component: Recommendation
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? `/` : `/`,
  routes,
  scrollBehavior (to, from, savedPosition) {
    //console.log(to, from, savedPosition)
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else if (savedPosition) {
      return savedPosition;
    } else if (to !== from) {
      return { x: 0, y: 0 }
    } 
  }
});

export default router;
