<template>
  <span>${{displayValue | toCurrency}}</span>
</template>

<script>
import gsap from 'gsap';
export default {
    name:'AnimatedInteger',    
    props: {
        value: { default: 0 }
    },
    data: () => ({
        displayValue: 0,
        tweenValue: 0
    }),  
    mounted() {
        this.displayValue = this.value;
        this.tweenValue = this.value;
    },
    filters: {
        toCurrency: function (value) {
            if (typeof value !== "number") {
                return value;
            }
            var formatter = new Intl.NumberFormat("en-US", {        
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }
        );
        return formatter.format(value);
        }
    },
    watch: {
        value() {
            gsap.to(this, {
                tweenValue: this.value,
                onUpdate: () => {
                    this.displayValue = Math.ceil(this.tweenValue);
                }
            });
        }
    }  
}
</script>