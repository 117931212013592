<template>
  <section id="contribution">
        <div class="payment-type">
            <span>Pay by:</span>
            <div>
                <button :class="[isCheck ? 'active' : '']" @click="fee=1; isCheck=true;"><img src="@/assets/icons/icon_pencil.svg" alt=""> <span>Check/Wire</span></button>
                <button :class="[isCheck ? '' : 'active']" @click="fee=1.035; isCheck=false;"><img src="@/assets/icons/icon_credit-card.svg" alt=""> <span>Credit Card</span></button>
            </div>
        </div>
        <div class="pricing-calculator">
            <div class='total-holder'>
                <div class="total">
                    <p>Total Price</p>   
                    <AnimatedInteger :value="total * fee" />                 
                </div>
                <transition name="slide-fade" mode="out-in" appear>
                    <div v-show="!isCheck" class="note">
                        *3.5% credit card<br/>payment fee
                    </div>
                </transition>
            </div>
            <div class="payment-details">
                <h4>Payment Details</h4>
                <div><p class="label">Due at signing</p><p class="label"><AnimatedInteger :value="signing * fee" /></p></div>
                <div><p class="label">Due November 15th, 2021</p><p class="label"><AnimatedInteger :value="p0 * fee" /></p></div>
                <div><p class="label">Due December 15th, 2021</p><p class="label"><AnimatedInteger :value="p1 * fee" /></p></div>
                <div class='note'><p class="label">Recorded donation amount (for tax purposes): </p> <p class="label"> <AnimatedInteger :value="donation + (total * (fee - 1))" /></p></div>
            </div>
        </div>
    </section>    
</template>

<script>
import AnimatedInteger from '@/patterns/AnimatedInteger';
export default {
    name:'ContributionCalculator',
    components:{AnimatedInteger},
    props: {
        donation:Number,
        total:Number,
        signing:Number,
        p0:Number,
        p1:Number,        
        isLeader:Boolean,
    }, 
    data: () => ({        
        isCheck:true,
        fee:1
     }),  
}
</script>

<style lang="scss" scoped>
    #contribution {
        background: linear-gradient(135deg, #202020 0%, #161616 100%);
        padding:65px 0;
    }
    .payment-type {        
        max-width: 1024px;
        margin:0 auto;
        text-align: left;
        padding:15px;

        color: #FFFFFF;
        font-family: "Gotham Narrow";
        font-size: 1.3em;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        > div {
            break-inside: avoid;
            display: flex;
            flex-direction: column;
        }
        span {
            display: inline-block;
            margin-right:30px;
            padding-bottom:20px;
        }
        button {
            width: 100%;
            text-transform: uppercase;
            text-align: center;
            border: 1px solid #C2AB64;
            border-radius: 3px;
            height: 55px;            
            color: #FFF;
            font-family: "Gotham Narrow";
            font-size: 0.6em;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1;
            cursor: pointer;
            transition: background 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            margin-bottom: 10px;
            &:first-child {                
                margin-right:10px;
            }      
            &.active {
                background-color: #C2AB64;
            }   
            span {
                margin:0;
                padding:0;
            }   
            img {
                margin-right:12px;
            }
            img, span {
                vertical-align: middle;
            }
        }
    }
    .pricing-calculator {
        display: block;
        text-align: left;
        max-width: 1044px;
        margin: 0 auto;
        padding:35px;
        justify-content: flex-start;
        .total {
            display: inline-block;
            border-radius: 3px;
            background-color: #FFFFFF;
            flex-grow: 0;
            flex-shrink: 0;
            padding:25px 35px;            
            margin:0 auto;
            width:210px;
            box-sizing: border-box;

            color: #C2AB64;
            font-family: "Gotham Narrow";
            font-size: 2em;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            text-align: left;
            p {                
                margin:0;
                margin-bottom: 6px;
                color: #161616;                
                font-size: 0.6em;
            }
        }        
        .payment-details, .total-holder {
            .note {
                font-size: 0.8em;                
                span {
                    margin-left:3px;
                }
            }
        }
        .total-holder {
            .note {
                margin-top:15px;                         
            }
        }
        .payment-details {
            flex-grow: 1;
            text-align: left;
            h4, div {
                &:not(.note) {
                    border-bottom:1px solid #414141;
                }
                font-size: 1em;
                letter-spacing: 0;
                line-height: 1;
            }
            h4 {
                padding-bottom: 18px;                
                font-weight: bold;                
            }
            div {
                padding-bottom: 10px;
                padding-top: 8px;
                font-weight: 300;   
                &.note {
                    text-align: right;
                    span {
                        min-width: 55px;
                    }
                }             
            }
            .label {
                display: inline;
            }
            span {
                float: right;
            }
        }
    }
    @media (min-width:560px) {
        .payment-type {
            border-top:1px solid #414141;
            border-bottom:1px solid #414141;
            > div {
                display: inline-block;
            }
            button {
                width:195px;
                margin-bottom: 0;
            }
        }
        .pricing-calculator {
            display: flex;
            .total-holder {
                margin-right:30px;
            }
        }
    }
</style>