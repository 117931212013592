<template>
    <section>
        <slot name="intro"></slot>
    </section>
</template>

<script>
    export default {
        name:'SectionIntro',        
    }
</script>
<style lang="scss" scoped>
    h2 {
        font-weight: bold;
        font-size: 2.2em;
        letter-spacing: 4px;
        line-height: 1.25;
        margin-top:95px;
    }
    p {
        box-sizing: border-box;
        max-width: 768px;        
        margin:0 auto;
        padding:0;
        &:first-child {
            margin:2.5em auto;
            padding:1rem;
        }
        &:last-child {
            margin:2.5em auto;
            padding:1rem;
            padding-top:0;
        }
    }
</style>