<template>
    <div class="package-type">
        <div class="packages">
            <button 
                :class="[isLeader ? 'active' : '']" 
                :style="{
                        'background-image':'url(' + require('@/assets/gallery/package_leaders.jpg') + ')',
                }"
                @click="$emit('packageSelected', 'leader'); isLeader = true"><span>Leaders<br/>Circle</span></button>
            <button 
                :class="[isLeader ? '' : 'active']" 
                :style="{
                        'background-image':'url(' + require('@/assets/gallery/package_premier.jpg') + ')',
                }"
                @click="$emit('packageSelected', 'premier'); isLeader = false"><span>Premier<br/>Circle</span></button>
        </div>      
    </div>  
</template>

<script>
export default {
    name:'PackagesButtons',
    data: () => ({        
        isLeader:true,
     }),  
}
</script>

<style lang="scss" scoped>
    .packages {
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        background:#fff;
        display: flex;
        padding:10px;
        margin-bottom:-35px;
        position: relative;        
        button {
            text-transform: uppercase;            
            background-size: cover;
            background-position: center;
            color:#fff;
            flex-grow: 1;            
            font-family: "Gotham Narrow";
            font-size: 1.2em;
            font-weight: 900;
            letter-spacing: 2px;
            line-height: 1.1;
            text-align: left;
            height:110px;
            padding:30px;
            opacity: 0.5;
            cursor: pointer;
            transition: opacity 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            position: relative;
            &:before {
                content:"";
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
            }
            &:first-child {
                margin-right: 10px;
            }
            &.active {
                opacity: 1;
            }
            span {
                position: relative;
            }
        }
    }
    .package-type {
        max-width: 1044px;
        margin:0 auto;        
        &:before {
            content:" ";
            max-width: 1024px;
            margin:0 auto;
            width: 100%;
            height:1px;
            display: block;
        }
        &:before {            
            background:#D9D9D9;
            margin-bottom:35px;                        
        }
    }
</style>