import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sessionDbId: "",
    baseUrl: "https://championla.vipfanportal.com/api/",
    navItems: [
      {
        image: "tile_hosting-the-world.jpg",
        title: "Hosting the World",
        subtitle: "Explore our LA",
        path: "/hosting-the-world",
        items: [
          {
            title: "Our LA",
            path: "/hosting-the-world/our-la",
          },
          {
            title: "Events",
            path: "/hosting-the-world/events",
          },
        ],
      },
      {
        image: "tile_championLA.jpg",
        title: "ChampionLA",
        subtitle: "Elevating our City",
        path: "/champion-la",
        items: [
          {
            title: "Why ChampionLA?",
            path: "/champion-la/why-champion-la",
          },
          {
            title: "ROI on Events",
            path: "/champion-la/roi-on-events",
          },
          {
            title: "Use of Funds",
            path: "/champion-la/use-of-funds",
          },
          {
            title: "Who is ChampionLA?",
            path: "/champion-la/who-is-champion-la",
          },
        ],
      },
      {
        image: "tile_your-opportunity.jpg",
        title: "Your Opportunity",
        subtitle: "Package Options",
        path: "/your-opportunity",
        items: [
          {
            title: "Packages",
            path: "/your-opportunity/packages",
          },
          {
            title: "Above & Beyond",
            path: "/your-opportunity/above-and-beyond",
          },
          {
            title: "Contribution",
            path: "/your-opportunity/contribution",
          },
        ],
      },
    ],
    superbowlExpenses: [
      {
        title: "Game Operations/Venue",
        description: "",
        cost: "$6.0",
        suffix: "million",
      },
      {
        title: "Ancillary Events",
        description: "(all other events including practice facilities)",
        cost: "$11.4",
        suffix: "million",
      },
      {
        title: "Charity/Legacy Initiatives",
        description: "",
        cost: "$1.2",
        suffix: "million",
      },
      {
        title: "Transportation, Operations & Logistics",
        description: "",
        cost: "$3.2",
        suffix: "million",
      },
      {
        title: "PR, Promotion, Decor & Marketing",
        description: "",
        cost: "$1.0",
        suffix: "million",
      },
      {
        title: "City Services",
        description: "(police, fire, DOT, etc)",
        cost: "$3.6",
        suffix: "million",
      },
      {
        title: "Team Accommodations & Hospitality",
        description: "",
        cost: "$1.3",
        suffix: "million",
      },
      {
        title: "Personnel, Finance & Administration",
        description: "",
        cost: "$4.2",
        suffix: "million",
      },
      {
        title: "Volunteers",
        description: "(food, uniforms, training, background checks, parking)",
        cost: "$1.3",
        suffix: "million",
      },
      {
        title: "Media",
        description: "",
        cost: "$1.5",
        suffix: "million",
      },
    ],
    packageInfoTypes: [
      {
        name: "Marquee<br/>Events",
        image: "comparison_marquee.jpg",
        tabs: [
          {
            name: "Super Bowl LVI (February 2022)",
            benefits: [
              {
                title: "Game Tickets",
                description:
                  "Experience Super Bowl LVI at SoFi Stadium. Tier 2 tickets for Super Bowl.",
                premierDescription:
                  "Experience Super Bowl LVI at SoFi Stadium.",
                genericDescription:
                  "Experience Super Bowl LVI at SoFi Stadium.",
                leader: "6 tickets <span>Tier 2</span>",
                premier: "2 tickets <span>Tier 3</span>",
                leaderNum: "6",
                premierNum: "2",
                icon: true,
                image: "benefit_game-tickets.jpg",
              },
              {
                title: "Pre-Game Tailgate",
                description:
                  "Tailgate in style at Super Bowl LVI Pre-Game ChampionLA Tailgate.",
                leader: "6 passes",
                premier: "2 passes",
                leaderNum: "6",
                premierNum: "2",
                icon: true,
                image: "benefit_leaders-pre-game-tailgate.jpg",
              },
              {
                title: "Game Day Parking",
                description:
                  "Parking pass provided to you for game day (offsite)",
                leader: "1 offsite parking pass",
                premier: "1 offsite parking pass",
                leaderNum: "1",
                premierNum: "1",
                icon: true,
                image: "benefit_gameday-parking.jpg",
              },
              {
                title: "Super Bowl Experience",
                description:
                  "Access to a family friendly, interactive event celebrating the NFL at the Los Angeles Convention Center and L.A. LIVE",
                leader: "6 passes",
                premier: "2 passes",
                leaderNum: "6",
                premierNum: "2",
                icon: true,
                image: "benefit_super-bowl-experience.jpg",
              },
            ],
          },
          {
            name:
              "College Football Playoff National Championship (January 2023)",
            benefits: [
              {
                title: "Game Tickets",
                description:
                  "Experience the College Football Playoff National Championship on January 9, 2023. Tier 1 bowl tickets (opportunity to upgrade to ChampionLA suite tickets, at an additional cost)",
                premierDescription:
                  "Experience the College Football Playoff National Championship on January 9, 2023. Tier 2 bowl tickets (opportunity to upgrade to ChampionLA suite tickets, at an additional cost)",
                genericDescription:
                  "Experience the College Football Playoff National Championship on January 9, 2023. Tiered bowl tickets (opportunity to upgrade to ChampionLA suite tickets, at an additional cost)",
                leader: "6 tickets <span>Tier 1</span>",
                premier: "4 tickets <span>Tier 2</span>",
                leaderNum: "6",
                premierNum: "4",
                icon: true,
                image: "benefit_game-tickets.jpg",
              },
              {
                title: "Pre-Game Tailgate",
                description:
                  "Enjoy a pregame experience with catering and live entertainment.",
                premierDescription:
                  "Enjoy a pregame experience with catering and live entertainment.",
                genericDescription:
                  "Enjoy a pregame experience with catering and live entertainment.",
                leader: "6 passes",
                premier: "4 passes",
                leaderNum: "6",
                premierNum: "4",
                icon: true,
                image: "benefit_leaders-pre-game-tailgate.jpg",
                premierImage: "benefit_premier-cfp_pre-game-tailgate.jpg",
              },
              {
                title: "Game Day Parking",
                description:
                  "Parking pass provided to you for game day (offsite)",
                leader: "1 offsite parking pass",
                premier: "1 offsite parking pass",
                leaderNum: "1",
                premierNum: "1",
                icon: true,
                image: "benefit_gameday-parking.jpg",
              },
              {
                title: "Playoff Fan Central",
                description:
                  "Experience this family friendly, interactive event celebrating college football",
                leader: "6 passes",
                premier: "4 passes",
                leaderNum: "6",
                premierNum: "4",
                icon: true,
                image: "benefit_playoff-fan-central.jpg",
              },
            ],
          },
          {
            name: "LA Bowl (December 2021)",
            benefits: [
              {
                title: "Suite Tickets",
                description:
                  "Experience the LA Bowl, featuring teams from the Mountain West and Pac-12 conferences, from a Perch suite at SoFi Stadium",
                leader: "4 tickets",
                premier: "4 tickets",
                leaderNum: "4",
                premierNum: "4",
                icon: true,
                image: "benefit_suite-tickets.jpg",
              },
              {
                title: "Pre-Game Tailgate",
                description:
                  "Enjoy the official LA Bowl Tailgate at SoFi Stadium and Hollywood Park",
                leader: "4 passes",
                premier: "4 passes",
                leaderNum: "4",
                premierNum: "4",
                icon: true,
                image: "benefit_la-bowl_pre-game-tailgate.jpg",
              },
              {
                title: "Field Passes",
                description:
                  "Step on the field with pre and post-game field access",
                leader: "4 passes",
                premier: "4 passes",
                leaderNum: "4",
                premierNum: "4",
                icon: true,
                image: "benefit_la-bowl-field-passes.jpg",
              },
              {
                title: "Game Day Parking",
                description: "Parking pass provided to you for game day",
                leader: "1 parking pass",
                premier: "1 parking pass",
                leaderNum: "1",
                premierNum: "1",
                icon: true,
                image: "benefit_gameday-parking.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Exclusive<br/>Experiences",
        image: "comparison_exclusive-exp.jpg",
        tabs: [
          {
            benefits: [
              {
                title: "Rams VIP for a day",
                description:
                  "Experience a Rams game like never before, with exclusive VIP access, $125 team store shopping spree, and autographed football. (Date TBA)",
                leader: "4 passes",
                premier: "2 passes",
                leaderNum: "4",
                premierNum: "2",
                icon: true,
                image: "benefit_rams-vip-for-a-day.jpg",
              },
              {
                title: "Behind the Scenes @ NFL Media",
                description:
                  "Join fellow ChampionLA supporters for a look inside NFL Media headquarters at Hollywood Park (Date TBA)",
                leader: "4 passes",
                premier: "2 passes",
                leaderNum: "4",
                premierNum: "2",
                icon: true,
                image: "benefit_behind-the-scenes-nfl.jpg",
              },
              {
                title: "Zoom Call with Rams player personnel",
                description:
                  "Learn what it takes to put together Super Bowl-caliber teams on a Zoom call with Rams player personnel (Date TBA)",
                leader: "2 guests",
                premier: "2 guests",
                leaderNum: "2",
                premierNum: "2",
                icon: true,
                image: "benefit_zoom-call-with-rams-player.jpg",
              },
              {
                title: "Kroenke Vineyards<br/>Virtual Wine Tasting Event",
                description:
                  "Learn about the award-winning wines of Kroenke Vineyards as you sip on two custom bottles, shipped to you in advance (Date TBA)",
                leader: "4 guests",
                premier: "2 guests",
                leaderNum: "4",
                premierNum: "2",
                icon: true,
                image: "benefit_kroenke-vinyards.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Additional<br/>Hospitality",
        image: "comparison_additional-hospitality.jpg",
        tabs: [
          {
            benefits: [
              {
                title: "ChampionLA Day of Golf",
                description:
                  "Participate in the ChampionLA Day of Golf at the Riviera Country Club",
                leader: "2 players",
                premier: "1 player",
                leaderNum: "2",
                premierNum: "1",
                icon: true,
                image: "benefit_day-of-golf.jpg",
              },
              {
                title: "Lakers All-Access Event",
                description:
                  "Go behind-the-scenes of the Lakers with locker room tours, player autograph sessions, and a dinner on the court featuring a panel of Laker insiders",
                leader: "2 passes",
                premier: "N/A",
                leaderNum: "2",
                premierNum: "0",
                icon: true,
                image: "benefit_lakers-all-access.jpg",
              },
              {
                title: "Dodgers All-Access Event",
                description:
                  "Go behind-the-scenes of the Los Angeles Dodgers with batting cage and bullpen experiences, player autograph sessions, and a dinner on the field featuring a panel of Dodgers insiders",
                leader: "2 passes",
                premier: "N/A",
                leaderNum: "2",
                premierNum: "0",
                icon: true,
                image: "benefit_dodgers-all-access.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Special<br/>Access",
        image: "comparison_special-access.jpg",
        tabs: [
          {
            benefits: [
              {
                title: "Pre-Sale Opportunities",
                description:
                  "Pre-sale access throughout 2022 for games, concerts, and events at SoFi Stadium. (Major one-time events like Super Bowl and College Football Playoff National Championship excluded.)",
                leader:
                  "2nd priority after suite holders and season ticket holders",
                premier:
                  "3rd priority after suite holders and season ticket holders",
                leaderNum:
                  "2nd priority after suite holders and season ticket holders",
                premierNum:
                  "3rd priority after suite holders and season ticket holders",
                icon: false,
                image: "benefit_presale-opportunities.jpg",
              },
              {
                title: "Marquee Event Ticket Access",
                description:
                  "Access to purchase other marquee events throughout Los Angeles, including <ul><li>Suite tickets to the 2023 GRAMMY Awards</li><li>Club seats to the 2023 Rose Bowl Game</li></ul>",
                leader: "4 tickets",
                premier: "2 tickets",
                leaderNum: "4",
                premierNum: "2",
                icon: true,
                image: "benefit_marquee-event-ticket-access.jpg",
              },
            ],
          },
        ],
      },
    ],
    modal: false,
    blockScroll: false,
  },
  mutations: {
    setSessionDBid(state, newValue) {
      state.sessionDbId = newValue;
    },
  },
  actions: {},
  modules: {},
});
