<template>
    <section v-if="showLogin" >
        <ForwardTrack @login="showLogin = false" :preview="preview" :purl="purl"/>
    </section>
    <section v-else>
        <VideoSection :hasButton="false"  trackingId="1" v-waypoint="{  trackingId:1, active: true, callback: onWaypoint, options: intersectionOptions}"/>   
        <div class='recommendation-message' trackingId="10" v-if="showSummary" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions}">
            <div>
                <div class="content">
                    <p class="salutation">Hello, <span>{{clientName}}</span></p>                
                    <p v-if="message !== ''" v-html="message"/>
                    <HashButton light href="#recommendation-intro" text="View Your Opportunity" />
                </div>
                <div class="rep">
                    <p class="label">Your ChampionLA Contact</p>
                    <div class="rep-photo"><img :src="getRepPhoto()" alt=""></div>
                    <div class="rep-info">
                        <p class="rep-name"><span>{{repFirst}}</span> <span class='rep-last'>{{repLast}}</span></p>
                        <p class="position">{{repPosition !== '' && repPosition !== 'null' ? repPosition : ''}}</p>
                        <p><a :href="`mailto:${repEmail}`">{{repEmail}}</a> | <span>{{repPhone}}</span></p>
                    </div>
                </div>            
            </div>            
        </div>
        <div v-if="showVideoMessage" id="recommendation-intro">
            <div>
                <SectionIntro>
                    <template v-slot:intro>
                        <h2>
                            <div class="uppercase">Extraordinary Experiences.</div>
                            <div class="uppercase gold">Lasting Impact.</div>
                        </h2>            
                        <p>We believe sports and entertainment events can uplift our communities economically, culturally and socially. That’s why the Los Angeles Sports & Entertainment Commission created ChampionLA - to bring the world’s most prestigious events to our region, and help build a stronger, brighter community for us all.</p>
                    </template>
                </SectionIntro>
                <PlayButton @clicked="showModal = true; $store.state.blockScroll=true;" title="Coming Soon<br/>Super Bowl 2022"/>            
            </div>
        </div>
        <section class='contribution' v-if="packages.length > 1">
            <Packages @packageSelected="setSite"/>   
        </section>
        <section id="package" v-if="packages.length > 0" :trackingId="isLeader ? 2 : 3" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions}">
            <h3>Your Package Recommendation: <span class="grey">{{isLeader ? 'Leaders Circle' : 'Premier Circle'}}</span></h3>
        </section>
        <div id="recommendation-info" v-if="packages.length > 0">
            <div>                
                <section class="package-types">
                    <PackageTypes @typeSelected="typeSelected" link='benefits'/>            
                </section>
                <section class="package-tabs" id='benefits'>
                    <transition name="fade" mode="out-in" appear>
                        <PackageTabs @tabSelected="tabSelected" :selectedType="selectedType" :selectedTab="selectedTab"/>
                    </transition>        
                </section>
                <section class="package-benefits">
                    <Benefit v-for="(benefit, id) in $store.state.packageInfoTypes[selectedType].tabs[selectedTab].benefits" :key="id" :benefit="benefit" :type="type"/>
                </section>
            </div>
        </div>
        <ContributionCalculator :isLeader="isLeader" :donation="donation" :total="total" :signing="signing" :p0="p0" :p1="p1" v-if="packages.length > 0"/> 
        <Linear forcedPath='welcome' forcedTitle='Learn More About ChampionLA'/>
        <Modal v-if="showModal && showVideoMessage" @close="showModal = false; $store.state.blockScroll=false;"> 
            <template v-slot:content>
                <iframe
                    src="https://player.vimeo.com/video/534530865?autoplay=1"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    autoplay
                    allowfullscreen
                    controls
                ></iframe>
            </template>
        </Modal>
    </section>
</template>

<script>
import HashButton from '@/patterns/HashButton';
import Linear from '@/patterns/Linear';
import Modal from '@/components/Modal';
import SectionIntro from '@/patterns/SectionIntro';
import PlayButton from '@/components/svg/icon_play-btn.vue';
import PackageTypes from '@/patterns/PackageTypes';
import PackageTabs from '@/patterns/PackageTabs';
import Benefit from '@/patterns/Benefit';
import Packages from '@/patterns/Packages';
import VideoSection from '@/patterns/VideoSection';
import ContributionCalculator from '@/patterns/ContributionCalculator';
import ForwardTrack from '@/components/ForwardTrack';

import axios from 'axios';

export default {
    name:'Recommendation',
    components:{ForwardTrack, Packages, HashButton, Linear, SectionIntro, PlayButton, Modal, PackageTypes, PackageTabs, Benefit, VideoSection, ContributionCalculator},    
    data: () => ({         
        showLogin:true,
        showModal:false, 
        showSummary:false,
        showVideoMessage:false,
        selectedType:0,
        selectedTab:0, 
        clientName:'{Client}',
        clientEmail:'',
        repFirst:'{RepFirst}',
        repLast:'{Last}',
        message:'',
        repPosition:'{RepPosition}',
        repEmail:'test@championla.com',
        repPhone:'(000) 000-0000',
        donation:250000,
        total:300000,
        signing:100000,
        p0:100000,
        p1:100000,
        fee:1,
        isLeader:true,
        type:'leaderNum',
        packages:[],
        purl:undefined,
        preview:false,
        intersectionOptions: {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0, 1] 
        } 
    }), 
    mounted() {
        this.purl = this.$route.params.purl;
        if(this.purl.indexOf('preview.') == 0) {
            const previewPurl = this.purl.split('preview.');
            this.purl = previewPurl.pop();
            this.preview = true;
        }
        axios.get(this.$store.state.baseUrl + "SiteEndpoint.php?purl=" + this.purl)
        .then(this.getSite)
        .catch(function (error) {
            console.log(error);
        })
    },
    beforeDestroy: function () {},
    methods: {  
        onWaypoint({ el, going }) {
                        
            if(this.preview) {
                return;
            }
            if (going === this.$waypointMap.GOING_IN) {                          
                const post = {
                    method:"page",
                    purl:this.purl,
                    sessionDbId:this.$store.state.sessionDbId,
                    section:el.getAttribute('trackingId')
                }
                axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);                        
            }
        },
        getRepPhoto() {
            try {
                return require(`@/assets/reps/${this.repFirst.toLowerCase()}-${this.repLast.toLowerCase()}.jpg`)   
            } catch (error) {
                return require(`@/assets/reps/dev-team.jpg`)
            }            
        },           
        setSite(type) {
            this.type = type + 'Num';
            if(type === 'leader') {
                this.isLeader=true; 
                this.donation=250000; 
                this.total=300000; 
                this.signing=100000; 
                this.p0=100000; 
                this.p1=100000;
            } else if(type === 'premier') {
                this.isLeader=false; 
                this.donation=100000; 
                this.total=125000; 
                this.signing=42000; 
                this.p0=42000; 
                this.p1=41000;
            }
        },
        getSite(response) {
            if(this.purl !== response.data.site.purl) {
                this.$router.push('welcome');
            }
            this.clientName = decodeURIComponent(response.data.site.client.name);
            this.clientEmail = decodeURIComponent(response.data.site.client.email);
            const repName = decodeURIComponent(response.data.site.rep.first);
            const repNameParts = repName.split(" ");
            this.repLast = repNameParts.pop();
            this.repFirst = repNameParts.join(" ")
            
            this.repPosition = decodeURIComponent(response.data.site.rep.position);
            this.repPhone = decodeURIComponent(response.data.site.rep.phone);
            this.repEmail = decodeURIComponent(response.data.site.rep.email);

            response.data.site.pages.forEach(element => {
                if(element.id == "10") {
                    this.message = element.copy;
                    this.showSummary = true;
                } else if(element.id == "1") {
                    this.showVideoMessage = true;
                } else if(element.id === "2" || element.id === "3") {
                    this.packages.push(element.title);
                }
            });
            if(this.packages.length > 0) {
                this.packages[0] === 'Leaders Circle' ? this.setSite('leader') : this.setSite('premier');
            }
        },
        typeSelected(id) {
            this.selectedType = id; 
            this.selectedTab = 0;
        },        
        tabSelected(id) {
            this.selectedTab = id; 
        }
    },
};
</script>

<style lang="scss" scoped>   
    .contribution {           
        h3 {
            margin-top:95px;
        }
    }    
    .salutation {
        color: #161616;        
        font-family: "Gotham Narrow";
        font-size: 2.2em;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        white-space: normal;
        span {
            color: #C2AB64;
            display: inline-block;
        }
    }
    .recommendation-message {                
        font-size: 1em;
        font-family: "Gotham Narrow";
        line-height: 1;
        padding:25px;                
        position: relative;
        box-sizing: border-box;                
        margin-top:-125px;
        &:before {
            content:" ";
            background: linear-gradient(135deg, #202020 0%, #161616 100%);
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
            margin-top:95px;
        }
        > div {
            position: relative;
            background:#fff;            
            border-radius:3px;
            border:1px solid #fff;
            max-width: 1044px;
            margin:0 auto;
        }
        .content, .rep {
            width:100%;
            box-sizing: border-box;
        }
    } 
    .content {
        flex-grow: 1;
        color: #161616;        
        font-weight: 300;
        letter-spacing: 0;
        padding:20px;
        text-align: left;
        line-height: 1.5;
    }
    .rep {        
        box-sizing: border-box;
        flex-shrink: 0;
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        border-radius: 0 3px 3px 0;
        background: linear-gradient(135deg, #202020 0%, #161616 100%);                
        font-weight: bold;
        letter-spacing: 2px;        
        text-align: center;
        width:370px;
        padding-bottom:20px;
        p {
            margin:0;
        }
        .label {
            border-radius: 0 3px 0 0;
            background-color: #2D2D2D;
            text-transform: uppercase;
            color: #E1E1E1;            
            font-size: 0.8em;            
            letter-spacing: 1px;
            line-height: 1;            
            padding:10px;
        }
        .rep-name {
            text-transform: uppercase;
            color: #E1E1E1;
            font-size: 1em;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1;
        }
        .rep-last {
            color: #C2AB64;
        }
        .rep-photo {
            padding:35px 0;
            height:240px;
            img {
                border-radius: 100%;
                overflow: hidden;
            }            
        }
        .position {
            color:#E1E1E1;
            margin:10px 0;
        }
        .rep-info {
            color: #ADADAD;

            font-size: 0.8em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;            
        }
    }
    .linear {
        border-top:1px solid #414141;
    }
    #recommendation-intro {
        background: linear-gradient(135deg, #202020 0%, #161616 100%);
        overflow: auto;
        padding-top:0px;
        position: relative;
        > div {
            border-top:1px solid #414141;
            border-bottom:1px solid #414141;
            margin:30px auto;
            margin-top:5px;
            max-width: 1024px;
            padding-bottom:75px;       
            > section {
                max-width: 1024px; 
                width:calc(100% - 1em);
                margin:2.25em auto;                         
            }
        }        
    }     
    #recommendation-info {
        > div {
            border-top:1px solid #D9D9D9;
            border-bottom:1px solid #D9D9D9;
            margin:30px auto;
            max-width: 1024px;
            padding:30px 0;   
            box-sizing: border-box;    
        }  
    }
    .package-benefits {
        max-width: 1024px;
        margin:0 auto;
        padding:0 8px;        
    }    
    .package-types {
        background:#fff;
        padding:10px;        
        max-width: 1044px;
        margin:0 auto;
        margin-bottom:16px;
        box-sizing: border-box;
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        .types {
            padding:0;
        }
    }
    .package-tabs {
        max-width: 1024px;
        margin:20px auto;
        margin-bottom:16px;
        box-sizing: border-box;    
        border-bottom:1px solid #D9D9D9;
        padding:0 10px;
        scroll-margin-top: 90px;
    }
    #package {
        max-width: 1024px;
        margin:0 auto;
    }
    h3 {
        margin-top:95px;
        padding:0 1em;
        .grey {
            color:#7F7F7F;
        }
    }
    @media (min-width:750px) {
        .recommendation-message > div {
            display: flex;            
            .content {
                padding:100px;
            }
            .rep {
                max-width: 370px;
                width: 370px;
            }
        }
        .package-benefits {
            display: flex;
        }
    }
</style>
