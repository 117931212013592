<template>
  <div class="footer">
      <div>
        <ChampionLA />
        <div  v-for="(nav, id) in $store.state.navItems" :key="id" class="nav-section">
            <p>{{nav.title}}</p>
            <div v-for="(level2, id) in nav.items" :key="id" ><router-link :to="level2.path">{{level2.title}}</router-link></div>
        </div>        
        <div class="additional">
            <div class="social">
                <a target="_blank" href="https://www.instagram.com/lasec/"><img src="@/assets/logos/social_instagram.svg" alt=""></a>
                <a target="_blank" href="https://www.facebook.com/LASports"><img src="@/assets/logos/social_facebook.svg" alt=""></a>
                <a target="_blank" href="https://twitter.com/LASEC"><img src="@/assets/logos/social_twitter.svg" alt=""></a>
                <a target="_blank" href="http://www.linkedin.com/company/lasports"><img src="@/assets/logos/social_linkedin.svg" alt=""></a>
                <a target="_blank" href="https://www.youtube.com/user/LASportsReel"><img src="@/assets/logos/social_youtube.svg" alt=""></a>
            </div>
            <div><a target="_blank" href="https://lasec.net" class='gold'>lasec.net</a></div>
            <div><a target="_blank" href="https://www.lasec.net/privacy-policy/">Privacy Policy</a></div>
            <div><a target="_blank" href="https://www.lasec.net/terms-and-conditions/">Terms & Conditions</a></div>
        </div>
        </div>
    </div>
</template>

<script>
import ChampionLA from '@/components/svg/logo_champion-LA.vue';
export default {
    name:'Footer',
    components: {ChampionLA},    
}
</script>

<style lang="scss" scoped>    
    .social {
        margin-bottom:15px;
    }
    .social a {
        display:inline-block;
        justify-content: center;
        height: 25px;
        width: 25px;
        border-radius: 3px;
        background-color: #363636;
        text-align: center;
        transition:background-color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); 
        &:not(:last-child) {
            margin-right:4px;
        }
        img {
            vertical-align: middle;
        }
        &:hover {
            background-color: #C2AB64;
        }
    }
    .additional {
        > div:not(.social) {
            margin-bottom: 0.5em;
        }
    }
    .nav-section {
        > div {
            margin-bottom: 0.5em;
        }
    }
    p {
        margin:0.5em 0;        
        font-weight: bold;
    }
    a {
        color:#C7C7C7;
        user-select: none;        
        transition:color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); 
        &:hover {
            color:#C2AB64;
        }
    }
    .gold {
        color:#C2AB64;
        text-decoration: underline;
    }
    .footer {
        background:#161616;
        text-align: left;
        padding-top:80px;
        padding-bottom:70px;
        border-top:1px solid #414141;
        > div {
            display:flex;
            max-width: 1024px;
            margin:0 auto;
            align-items: center;
            flex-direction: column;
            text-align: center;
            .additional {
                margin-top:1em;
            }
            .nav-section {
                display:none;
            }
            > * {
                flex-grow:1,
            }
        }
    }
    @media (min-width:750px) {
        p {
            margin-top: 0;
        }
        .footer > div {            
            flex-direction: row;
            text-align: left;
            align-items: flex-start;
            .additional {
                margin-top: 0;
            }
            .nav-section {
                display:block;
            }
        }
    }
</style>