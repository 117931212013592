<template>
    <button class="play-video" @click="$emit('clicked')">
        <p class="gold uppercase" v-html="title"></p>
        <svg width="86" height="86" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
                <path fill-rule="nonzero" d="M36 53l20-11.5L36 30z"/>
                <circle stroke="#C2AB64" stroke-width="2" cx="42" cy="42" r="42"/>
            </g>
        </svg>
    </button>  
</template>

<script>
export default {
    name:'PlayButton',
    props: {
        title:String
    }
}
</script>

<style lang="scss" scoped>
    button {
        font-size: 0.75em;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 1;
        user-select: none;
        cursor: pointer;
        p {
            transition:all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); 
            margin-bottom:2rem;
            line-height: 1.7;
        }   
        stroke {
            transition:stroke 0.8s 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); 
        }     
        path {
            transition:all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); 
            fill:#C2AB64;
            transform-origin: center;
        }
        &:hover {
            p {
                color:#fff;
            }
            path {
                fill:#fff;
                transform: scale(1.35);
            }
            circle {
                stroke:#fff;
            }
        }
    }
</style>