<template>
    <div class="tabs" v-if="$store.state.packageInfoTypes[selectedType].tabs.length > 1">
        <button v-for="(tab, id) in $store.state.packageInfoTypes[selectedType].tabs" :key="id" 
            @click="selectedTab = id; $emit('tabSelected', id)"
            :class="[selectedTab == id ? 'active' : '']"
        >{{tab.name}}</button>
    </div>
</template>

<script>
export default {
    name:'PackageTabs',
    props: {
        selectedType:Number,
        selectedTab:Number, 
    },
    data: () => ({
      
    }),   
}
</script>

<style lang="scss" scoped>
    .tabs {
        padding:0;
        padding-bottom: 8px;
        text-align: left;
        button {
            user-select: none;
            cursor: pointer;
            border-radius: 3px;
            background-color: #ECECEC;
            transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            color: #898989;
            font-family: "Gotham Narrow";
            font-size: 0.9em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
            padding:6px;
            margin-bottom:8px;
            &:not(:last-child) {
                margin-right:8px;
            }
            &.active, &:hover {
                color:#fff;
                background-color: #C2AB64;
            }
        }
    }
</style>