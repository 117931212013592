<template>
  <div class='benefit' v-if="benefit[type] !== '0'" :class="[type]">
    <div class="image" :style="{ 'background-image':'url(' + require('@/assets/benefits/' + ((type === 'premierNum' && benefit.premierImage !== undefined) ? benefit.premierImage : benefit.image)) + ')',}">
        <div class="value" :class="benefit.icon ? '' : 'no-icon'"><TicketIcon v-if="benefit.icon" :fill="type == 'leaderNum' ? '#C2AB64' : '#3F3F3F'"/>{{benefit.icon ? benefit[type] : 'Priority'}}</div>
    </div>
    <div class='info'>
        <div class="title">
            <p v-html="benefit.title"/>            
        </div>
        <div class="description" v-html="(type === 'premierNum' && benefit.premierDescription !== undefined) ? benefit.premierDescription : benefit.description"/>
        <p class='note' v-if="!benefit.icon">{{benefit[type]}}</p>
    </div>                
</div>
</template>

<script>
import TicketIcon from '@/components/svg/icon_ticket';
export default {
    props : {
        benefit:Object,
        type:String,
    },
    components:{TicketIcon},
}
</script>

<style lang="scss" scoped>
    .benefit {
        flex-grow: 1;        
        width: 100%;
        display:inline-block;
        vertical-align: top;
        text-align: left;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:only-child {
            flex-grow: 0;
            width: 33%;
        }
        &.leaderNum {
            background: linear-gradient(180deg, #C2AB64 0%, #96844B 100%);
        }
        &.premierNum {
            background: linear-gradient(180deg, #929292 0%, #535353 100%);
            .value {
                color:#3F3F3F;
            }
            .title {
                border-color:#3F3F3F;
            }
        }
        &:not(:last-child) {
            margin-right:16px;
        }
        .image {
            width:100%;
            height:160px;
            position: relative;
            background-size:cover;
            background-position: center;
            &:after {

            }
        }
        .value {
            box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
            border-radius: 3px;
            background-color: #FFF;
            color: #C2AB64;
            font-family: "Gotham Narrow";
            font-size: 2em;
            font-weight: 900;
            letter-spacing: 0;
            display: inline-block;
            padding:4px 13px;
            position: absolute;
            left:25px;
            bottom:-25px;
            svg {
                margin-right:6px;
            }
            &.no-icon {                                                
                text-transform: uppercase;
            }
        }
        .info {
            padding:25px;
            box-sizing: border-box;
            max-height: 200px;
            overflow: auto;
            &::-webkit-scrollbar {
               width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: #161616;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #C2AB64;
            }
        }
        .title {
            text-transform: uppercase;
            font-family: "Gotham Narrow";
            font-size: 1.2em;
            font-weight: 900;
            letter-spacing: 2px;
            line-height: 23px;
            border-top:1px solid #91804A;
            border-bottom:1px solid #91804A;
            padding:6px 0;
            margin:1em 0;
            p {
                margin:0;
                padding:0;
            }    
        }    
        .note {
            font-size: 0.7em;
            line-height: 1;
            margin-top:6px;
            font-style: italic;
        }            
        .description {                        
            font-size: 0.8em;
            line-height: 1.4;
            margin:0;        
        }
    }
    @media (min-width:750px) {
        .benefit {
            &:not(:last-child) {
                margin-bottom: 0;        
            }
        }        
    }
</style>