<template>
    <section class='holder'>
        <VideoSection />
        <Impact />       
    </section>
</template>

<script>

import Impact from '@/components/Impact';
import VideoSection from '@/patterns/VideoSection';
export default {
    name:'Welcome',
    components:{Impact, VideoSection},    
};
</script>

<style lang="scss" scoped>
.holder {
    padding-bottom:10px;
    background:#161616;
}
</style>
