<template>
  <a :class="[light ? 'is-light' : 'is-dark']" :href="href">{{text}}</a>
</template>

<script>
export default {
    name:'HashButton',
    props: {
        text:String,
        href:String,
        light:Boolean
    }
}
</script>

<style lang="scss" scoped>
    a {
        display: inline-block;
        border-radius: 3px;        
        text-transform: uppercase;
        font-family: "Gotham Narrow";
        font-size: 0.75em;
        font-weight: bold;
        letter-spacing: 2px;
        padding:1.1rem 1.8rem;
        transition:all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);         
        color:#fff;
        &.is-light {            
            background-color: #C2AB64;
            border:1px solid #C2AB64;
        }
        &.is-dark {
            background-color: #161616;
        }
        &:hover {
            color:#161616;
            background:#fff;
        }
    }
</style>