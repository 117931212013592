<template>
  <section id="impact">
        <div>
            <SectionIntro>
                <template v-slot:intro>
                    <h2>
                        <div class="uppercase">Extraordinary Experiences.</div>
                        <div class="uppercase gold">Lasting Impact.</div>
                    </h2>            
                    <p>We believe sports and entertainment events can uplift our communities economically, culturally and socially. That’s why the Los Angeles Sports & Entertainment Commission created ChampionLA - to bring the world’s most prestigious events to our region, and help build a stronger, brighter community for us all.</p>
                </template>
            </SectionIntro>
            <PlayButton @clicked="showModal = true; $store.state.blockScroll=true;" title="Coming Soon<br/>Super Bowl 2022"/>
        </div>
        <CardNav />
        <div class="disclaimer">
            <button @click="showDisclaimerModal = true">Disclaimer: Solicitation of Funds</button>
        </div>
        <Modal v-if="showModal" @close="showModal = false; $store.state.blockScroll=false;"> 
            <template v-slot:content>
                <iframe
                    src="https://player.vimeo.com/video/534530865?autoplay=1"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    autoplay
                    allowfullscreen
                    controls
                ></iframe>
            </template>
        </Modal>
        <Modal v-if="showDisclaimerModal" @close="showDisclaimerModal = false; $store.state.blockScroll=false;"> 
            <template v-slot:content>
                <img src="@/assets/disclaimer/ChampionLA-Solicitation Package_p1.jpg" alt="" class='disclaimer-image'>
                <img src="@/assets/disclaimer/ChampionLA-Solicitation Package_p2.jpg" alt="" class='disclaimer-image'>
            </template>
        </Modal>
    </section>
</template>

<script>

import Modal from '@/components/Modal';
import CardNav from '@/patterns/CardNav';
import SectionIntro from '@/patterns/SectionIntro';
import PlayButton from '@/components/svg/icon_play-btn.vue';
export default {
    name:'Impact',
    components:{CardNav, PlayButton, SectionIntro, Modal},    
    data: () => ({ showModal:false, showDisclaimerModal:false }), 
    
}
</script>

<style lang="scss" scoped>    
    .disclaimer-image {
       max-width: 90%; 
       padding-top:25px;
       
    }  
    #impact .disclaimer {
        max-width: 1024px;
        padding:0;
        border:none;
        border-top:1px solid #414141;
        padding-top:35px;
        button {            
            max-width: 370px;
            border: 1px solid #C2AB64;
            border-radius: 3px;
            background-color: #C2AB64;
            text-transform: uppercase;
            text-align: center;
            display: inline-block;
            outline:none;
            width: 100%;
            padding:20px 15px;
            font-family: "Gotham Narrow";
            font-size: 0.75em;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1;
            color:#fff;
            margin:0;
            cursor: pointer;            
        }
    }
    #impact > div {
        max-width: 1024px; 
        width:calc(100% - 1em);
        margin:2.25em auto;                
        padding-bottom:75px;
        border-top:1px solid #414141;
        border-bottom:1px solid #414141;                
    }         
    iframe {
        width: 100%;
    }  
</style>