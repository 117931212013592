<template>
    <div class='linear' v-if="path !== undefined">
        <router-link :to="path">{{name}}<ArrowButton/></router-link>
    </div>
</template>

<script>
import ArrowButton from '@/patterns/ArrowButton';
export default {
    name: "Linear",
    components: {ArrowButton,},
    props : {
        forcedPath:String,
        forcedTitle:String
    },
    data: () => (
        {
            nextPath:undefined,
            path:undefined,
            name:""      
        }
    ),
     mounted() {
        this.setNextPath();
    },
    updated() {
        this.setNextPath();
    },
  methods : {
    setNextPath() {      
        if(this.forcedPath !== undefined) {
          this.path = this.forcedPath;
        }
        if(this.forcedTitle !== undefined) {
          this.name = this.forcedTitle;
        }

        if(this.forcedPath !== undefined) {
          return;
        }
        
        const currentIndex = this.getCurrentPath();
        const pathsLinearHidden = ['/welcome'];
        if(pathsLinearHidden.includes(this.$router.currentRoute.path)) {
            this.nextPath = undefined;
            return;
        }
        if (currentIndex == 0 || currentIndex == this.$router.options.routes.length - 1) {
            //at end or beginning
            this.nextPath = undefined;
            return;
        }
        this.nextPath = this.$router.options.routes[currentIndex + 1];
        this.name = this.nextPath.name;
        this.path = this.nextPath.path;
    },
    getCurrentPath() {
      let currentIndex = 0;
      for (let index = 0; index < this.$router.options.routes.length; index++) {
        const element = this.$router.options.routes[index];
        if (element.path === this.$router.currentRoute.path) {
          currentIndex = index;
          break;
        }
      }
      return currentIndex;
    },
  }  
}
</script>

<style lang="scss" scoped>
  .linear {
    background: linear-gradient(135deg, #202020 0%, #161616 100%);
    padding:30px;
    text-align: center;
    a {
      text-transform: uppercase;
      color: #C2AB64;
      font-family: "Gotham Narrow";
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 1;
      transition: color 0.25s cubic-bezier(1, 0.5, 0.8, 1);
      .arrow {
        transition: margin 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        margin-left:20px;
      }
      &:hover {
        color:#fff;
        .arrow {
          margin-left:30px;
        }
      }
    }
  }
</style>