<template>
    <div class="types" v-if="link == ''">
        <button v-for="(type, id) in $store.state.packageInfoTypes" :key="id" 
                @click="selectedId = id; $emit('typeSelected', id)"
                :class="[selectedId == id ? 'active' : '']"
                :style="{ 'background-image':'url(' + require('@/assets/comparison/' + type.image) + ')',}"
                
        >
            <span v-html="type.name" />
        </button>
    </div>
    <div class="types" v-else>
        <button v-for="(type, id) in $store.state.packageInfoTypes" :key="id" 
                @click="selectedId = id; $emit('typeSelected', id)"
                :class="[selectedId == id ? 'active' : '']"
                :style="{ 'background-image':'url(' + require('@/assets/comparison/' + type.image) + ')',}"
                
        >
            <span v-html="type.name" />
        </button>
    </div>
</template>

<script>
export default {
    name:'PackageTypes',
    props : {
        link:{
            type:String,
            default:'',            
        }
    },
    data: () => ({
      selectedId:0, 
    }),   
}
</script>

<style lang="scss" scoped>
    .types {
        padding-bottom: 16px;
        button, a {
            display: block;
            width: 100%;            
            cursor: pointer;
            flex-grow: 1;
            box-sizing: border-box;
            background-size:cover;
            height:110px;
            border-radius: 3px;
            color: #FFFFFF;
            font-family: "Gotham Narrow";
            font-size: 1.3em;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1.2;
            text-align: left;
            user-select: none;
            padding:30px;
            opacity: 0.5;
            transition: opacity 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            position: relative;
            span {
                position: relative;
            }
            &:before {
                content: " ";
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            &.active {
                opacity: 1;
            }
            &:not(:last-child) {
                margin-right:8px;
                margin-bottom: 8px;
            }
        }
    }
    @media (min-width:700px) {
        .types {
            display: flex;
            button {
                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>