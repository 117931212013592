<template>
  <div class="login">
        <img class='watermark' src="@/assets/watermark.png" alt="">
        <div>
            <img src="@/assets/logo_tagline.svg" alt="">
            <div class="input username">
                <p>Username<span v-if="usernameError">*</span></p>
                <input @keypress="loginFromKeyboard" :class="[username === '' ? '' : 'entered']" type="text" name="username" id="username" v-model="username" placeholder="Enter your username">
            </div>
            <div class="input password">
                <p>Password<span v-if="passwordError">*</span></p>
                <input @keypress="loginFromKeyboard" :class="[password === '' ? '' : 'entered']" type="password" name="password" id="password" v-model="password" placeholder="Enter your password">
            </div>
            <p v-show="error">{{error}}</p>
            <button @click="checkLogin">Log In</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name:'Login',
    props : {
        requestedUsername:String,
        requestedPassword: {
            type:String,
            default:'championla'
        }
    },
    data: () => ({ 
        usernameError:false,
        passwordError:false,
        error:'',
        username:'',
        password:'',
    }),
    mounted() {
        const loggedInFrontend = window.sessionStorage.getItem('loggedInFrontend');
        if(loggedInFrontend !== null && loggedInFrontend.name !== "") {
            this.$emit("login");
        }
    },
    methods : {
        loginFromKeyboard(e) {
            if(e.keyCode == 13) {   //press enter
                this.checkLogin();
            }
        },
        loginResponse(response) {
            if(response.data.status == 'ok') {
                this.$emit("login");
                const loggedInFrontend = {
                    name:this.username
                }
                window.sessionStorage.setItem('loggedInFrontend', JSON.stringify(loggedInFrontend));
            } else {
                this.loginFailed();
            }
        },
        checkLogin() {
            this.usernameError = false;
            this.passwordError = false;      
            if(this.username.trim() == '') {
                this.usernameError = true;
            }
            if(this.password.trim() == '') {
                this.passwordError = true;
            }
            if(this.usernameError || this.passwordError) {
                this.loginFailed();
                return;
            }
            axios.post(this.$store.state.baseUrl + "SubmitLogin.php", {username:this.username, password:this.password})
            .then(this.loginResponse)
            .catch(function (error) {
                console.log(error);
            })            
            
        },
        loginFailed() {
            if(this.username.trim() === "") {
                this.error = "Please enter your username";
                this.usernameError = true;
            }
            if(this.password.trim() === "") {
                this.error = "Please enter your password";
                this.passwordError = true;
            }
            if(this.username.trim() === "" && this.password.trim() === "") {
                this.error = "Please enter your username and password";
            }
            if(this.username.trim() !== "" && this.password.trim() !== "") {
                this.error = 'Your username/password are incorrect';
            }            
        },
    }
}
</script>

<style lang="scss" scoped> 
    .watermark {
        position: absolute;
        top:0;
        left:0;        
    }
    .login {
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E9E9E9 100%);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        overflow: hidden;
        > div {
            width: 240px;
            position: relative;
        }
        button {
            border: 1px solid #C2AB64;
            border-radius: 3px;
            background-color: #C2AB64;
            text-transform: uppercase;
            text-align: center;
            display: block;
            outline:none;
            width: 100%;
            padding:20px 15px;
            font-family: "Gotham Narrow";
            font-size: 0.75em;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1;
            color:#fff;
            margin-top:25px;
            cursor: pointer;
        }
        .username {
            margin-top:40px;
            margin-bottom: 20px;
            padding-top:30px;
            border-top:1px solid #d9d9d9;        
        }
        p {
            color: #C80000;        
            font-size: 0.75em;
        }
        .input {
            color: #161616;
            font-family: "Gotham Narrow";
            font-weight: 300;
            line-height: 1;  
            letter-spacing: 0;
            box-sizing: border-box;   
            p {
                color: #161616;
            }     
            input {
                font-family: "Gotham Narrow";
                border: 1px solid rgba(255,255,255,0.3);
                border-radius: 2px;
                background-color: #E4E4E4;
                display: block;
                width:100%;
                padding:20px 12px;
                box-sizing: border-box;
                font-size: 1rem;       
                color: #979797;
                border-bottom:5px solid #C1C1C1;   
                outline: none;
                transition:all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);             
                &:focus, &.entered {
                    color: #161616;
                    border-bottom:5px solid #C2AB64;   
                }  
            }
        }
    }  
</style>