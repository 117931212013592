<template>
  <div class="login">
        <img class='watermark' src="@/assets/watermark.png" alt="">
        <div>
            <img src="@/assets/logo_tagline.svg" alt="">
            <p>Welcome!</p>
            <p>Let us know who is viewing this presentation, an exciting opportunity is at your fingertips.</p>
            <div class="input username">
                <p>First Name<span v-if="usernameError">*</span></p>
                <input @keypress="loginFromKeyboard" :class="[username === '' ? '' : 'entered']" type="text" name="username" id="username" v-model="username" placeholder="First Name">
            </div>            
            <p class='error' v-show="error">{{error}}</p>
            <button @click="checkLogin">View your Opportunity</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name:'Login',
    props : {   
        purl:String,
        preview:{
            type:Boolean,
            default:true
        }     
    },
    data: () => ({ 
        usernameError:false,
        error:'',
        username:'',
    }),
    mounted() {
        const person = window.sessionStorage.getItem('user');
        if(person !== null && person.name !== "") {
            this.$emit("login");
        }
    },
    methods : {
        loginFromKeyboard(e) {
            if(e.keyCode == 13) {   //press enter
                this.checkLogin();
            }
        },
        trackVisit(response) {
            if(response.data.status == 'ok') {
                this.$store.commit('setSessionDBid', response.data.sessionDbId)
                this.$emit("login");
            }
        },
        addVisit() {
            if(this.preview) {
                this.$emit("login");
                return;
            }
            const post = {
                method:"visit",
                purl:this.purl,
                user:this.username
            }
            axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post)
            .then(this.trackVisit)
            .catch(function (error) {
                console.log(error);
            })
        },
        checkLogin() {
            this.usernameError = false;
            if(this.username !== "") {                
                //const person = {
                //    name:this.username
                //}
                //window.sessionStorage.setItem('user', JSON.stringify(person));
                const loggedInFrontend = {
                    name:this.username
                }
                window.sessionStorage.setItem('loggedInFrontend', JSON.stringify(loggedInFrontend));
                this.addVisit();
            } else {
                this.loginFailed();
            }
            
        },
        loginFailed() {
            if(this.username === "") {
                this.error = "Please enter your name";
                this.usernameError = true;
            }                        
        },
    }
}
</script>

<style lang="scss" scoped> 
    .watermark {
        position: absolute;
        top:0;
        left:0;        
    }
    p {
        color: #161616;
        font-family: "Gotham Narrow";
        font-size: 1em;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.7e;
        text-align: center;
    }
    .login {
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E9E9E9 100%);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        > div {
            max-width: 260px;
            position: relative;
        }
        button {
            border: 1px solid #C2AB64;
            border-radius: 3px;
            background-color: #C2AB64;
            text-transform: uppercase;
            text-align: center;
            display: block;
            outline:none;
            width: 100%;
            padding:20px 15px;
            font-family: "Gotham Narrow";
            font-size: 0.75em;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1;
            color:#fff;
            margin-top:25px;
            cursor: pointer;
        }
        .username {
            margin-top:40px;
            margin-bottom: 20px;
            padding-top:30px;
            border-top:1px solid #d9d9d9;        
        }
        .input p, .error {
            color: #C80000;        
            font-size: 0.75em;
            text-align: left;
        }
        .input {
            color: #161616;
            font-family: "Gotham Narrow";
            font-weight: 300;
            line-height: 1;  
            letter-spacing: 0;
            box-sizing: border-box;   
            p {
                color: #161616;
            }     
            input {
                font-family: "Gotham Narrow";
                border: 1px solid rgba(255,255,255,0.3);
                border-radius: 2px;
                background-color: #E4E4E4;
                display: block;
                width:100%;
                padding:20px 12px;
                box-sizing: border-box;
                font-size: 1rem;       
                color: #979797;
                border-bottom:5px solid #C1C1C1;   
                outline: none;
                transition:all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);             
                &:focus, &.entered {
                    color: #161616;
                    border-bottom:5px solid #C2AB64;   
                }  
            }
        }
    }  
</style>