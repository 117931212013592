<template>
  <div id="app">
    <section v-if="showLogin && $router.currentRoute.name !== 'Your Package Recommendation'" >
        <Login @login="showLogin = false" requestedUsername='championla'/>
    </section> 
    <div v-else>
      <TopNav />
      <transition name="fade" mode="out-in">
        <router-view/>    
      </transition>
      <Footer />
    </div>
  </div>  
</template>

<script>
import Login from '@/components/Login';
import TopNav from '@/components/TopNav';
import Footer from '@/components/Footer';
export default {
  name: "App",
  components: {Login, Footer, TopNav},  
  computed: {
    blockScroll () {
      return this.$store.state.blockScroll;      
    }
  },
  watch: {
    blockScroll (value) {      
      const body = document.body;
      value ? body.classList.add('is-scrolling-blocked') : body.classList.remove('is-scrolling-blocked');
    }
  },
  data: () => ({         
    showLogin:true
  })
};
</script>

<style lang="scss">
  .fade-enter-active {
    transition: opacity 0.25s ease;
  }
  .fade-leave-active {
    transition: opacity 0.25s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .slide-fade-enter-active {
    transition: all 0.25s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-25px);
    opacity: 0;
  }
  .is-scrolling-blocked {
    overflow:hidden;
  }
  @font-face {
      font-family: 'Gotham Narrow';
      src: url('~@/assets/fonts/GothamNarrow-Bold.woff2') format('woff2'),
          url('~@/assets/fonts/GothamNarrow-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('~@/assets/fonts/GothamNarrow-Book.woff2') format('woff2'),
          url('~@/assets/fonts/GothamNarrow-Book.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('~@/assets/fonts/GothamNarrow-Black.woff2') format('woff2'),
          url('~@/assets/fonts/GothamNarrow-Black.woff') format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }

  html {
    scroll-behavior: smooth;
  }
  .gold {
    color:#C2AB64;
  }
  .uppercase {
    text-transform: uppercase;
  }
  body {
    margin: 0;
    padding:0;
    background-color: #FAFAFA;  
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #161616;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #C2AB64;
    }
  }
  button {
    margin:0;
    padding:0;
    border:none;
    outline:none;
    background:none;
  }
  a {
    text-decoration: none;
    color:inherit;
    user-select: none;
  }
  h3 {
      margin:0;
      color: #161616;
      font-family: "Gotham Narrow";
      font-size: 2em;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1;
      margin-bottom:1.25em;
      text-align: left;
      &.gold {
          color:#C2AB64;
      }
  }
  #app {
    font-family: 'Gotham Narrow', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;    
  }
</style>


