<template>
    <div class='top-nav'>
        <div class='nav' v-if="showRecommentdationNav">
            <div>
                <ChampionLA color="#000"/>
                <div class="nav-items">
                    <div  class="nav-section">
                        <router-link to="/welcome"><span>Learn More About</span> <span class="bold">ChampionLA</span></router-link>
                    </div>
                </div>
                <img src="@/assets/logos/logo_LA.png" alt="">
            </div>
        </div>
        <div class="nav" v-else :class="[mobileNavNeeded ? 'mobile-is-on' : '']">
            <div>
                <ChampionLA color="#000"/>
                <div v-if="!mobileNavNeeded"  class="nav-items" ref="desktopNav">
                    <div  class="nav-section">
                        <router-link to="/welcome"><span>Welcome</span></router-link>
                    </div>
                    <div  v-for="(nav, id) in $store.state.navItems" :key="id" class="nav-section">
                        <router-link :to="nav.path"><span>{{nav.title}}</span><NavArrow v-if="nav.items.length > 1" /></router-link>                    
                        <div class='dropdown-items'>
                            <router-link v-for="(level2, id) in nav.items" :key="id" :to="level2.path">{{level2.title}}</router-link>
                        </div>
                    </div>
                </div>
                <img v-if="!mobileNavNeeded" src="@/assets/logos/logo_LA.png" alt="">
                <div v-if="mobileNavNeeded" class="mobile-menu-toggle" @click="mobileNavOpen = true; $store.state.blockScroll = true;"></div>
            </div>  
            <div class='mobile-menu' v-if="mobileNavNeeded" :class="[mobileNavOpen ? 'open' : '']" @click="mobileNavOpen = false; $store.state.blockScroll = false;">
                <div>
                    <div class="menu-top">
                        <ChampionLA color="#000"/>
                        <img src="@/assets/logos/logo_LA.png" alt="">
                    </div>
                    <div class="nav-items">
                        <div  class="nav-section">
                            <router-link to="/welcome">Welcome</router-link>
                        </div>
                        <div  v-for="(nav, id) in $store.state.navItems" :key="id" class="nav-section">
                            <router-link :to="nav.path"><span>{{nav.title}}</span></router-link>                    
                            <div class='dropdown-items'>
                                <router-link v-for="(level2, id) in nav.items" :key="id" :to="level2.path">{{level2.title}}</router-link>
                            </div>
                        </div>
                    </div>            
                </div>            
            </div>        
        </div>  
    </div>  
</template>

<script>
import ChampionLA from '@/components/svg/logo_champion-LA.vue';
import NavArrow from '@/components/svg/icon_nav-arrow.vue';
export default {
    name:'TopNav',
    components: {ChampionLA, NavArrow},
     data: () => ({
        mobileNavOpen:false,
        mobileNavNeeded:false,
        widthToRevertAt:undefined,
        showRecommentdationNav:false
    }),
    watch:{
        '$route' (to){
            if(to.name == 'Your Package Recommendation') {
                this.showRecommentdationNav = true;
            } else {
                this.showRecommentdationNav = false;
            }
        }
    },    
    mounted: function() {            
        this.checkForMobileNav();
        window.addEventListener("resize", this.checkForMobileNav);
    },
    updated: function() {
        this.checkForMobileNav();
        window.addEventListener("resize", this.checkForMobileNav);
    },    
    beforeDestroy: function () {
        window.removeEventListener("resize", this.checkForMobileNav);
    },
    methods : {
        checkForMobileNav: function() {
            if(this.$router.currentRoute.name == 'Your Package Recommendation') {
                this.showRecommentdationNav = true;
            } else {
                this.showRecommentdationNav = false;
            }
            if(window && window.innerWidth > this.widthToRevertAt) {
                this.mobileNavNeeded = false;
                this.widthToRevertAt = undefined
            }
            if(this.$refs.desktopNav) {
                if(this.$refs.desktopNav.scrollHeight >= 260) {
                    this.mobileNavNeeded = true;
                    this.widthToRevertAt = window.innerWidth;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .nav .mobile-menu {
        max-width: unset;     
        align-items: flex-start;
    }
    .mobile-menu {        
        position:fixed;
        top:0;
        left:0;
        height:100%;
        width:100%;           
        box-sizing: border-box;    
        pointer-events: none;    
        background:rgba(0,0,0,0.0);
        transition:background 0.4s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); 
        &:after {
            width:100%;
            height:100%;
            flex-grow:1;
            flex-shrink: 0;            
            
            content:" ";            
        }
        > div {
            height:100%;            
            box-sizing: border-box;    
            flex-grow: 0;
            flex-shrink: 0;
            background:#fff;                    
            transform:translateX(-100%);
            transition:transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);          
            display:flex;
            flex-direction: column;
        }
        .menu-top {
            flex-shrink: 0;
            flex-grow: 0;
            padding:20px;
            margin-bottom:0;
        }
        .nav-items {
            margin-right: 0;
            height: auto;
            overflow: auto;
            flex-grow:1;
            padding:0 20px;
            padding-bottom: 20px;;
            box-sizing: border-box;
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                background-color: #161616;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #C2AB64;
            }
        }
        .nav-section {
            display: block;            
            height:auto;
            margin-bottom: 9px;
            margin-right: 0;
            text-align: left;
            a {
                background-color: #F3F3F3;  
                text-align: left;                
            }
            .dropdown-items {
                opacity: 1;
                position: relative;
                top:0;
                margin-top:9px;
                a {
                    padding:15px 0.5em;
                }
            }
        }
        &.open {
            transition:background 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); 
            background:rgba(0,0,0,0.8);         
            pointer-events: all;            
            > div {
                transition:transform 0.4s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); 
                transform:translateX(0);
            }
        }
    }
    .nav.mobile-is-on {
        padding-right:0;
        > div {
            justify-content: space-between;
            max-width: unset;
        }
    }
    .menu-top {
        margin-bottom:50px;
        display:flex;
        justify-content: space-between;
        svg, img {
            vertical-align: middle;
        }
        img {
            width:20px;            
            height:20px;            
        }
    }
    .mobile-menu-toggle {
        width:50px;
        height:50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:after {
            display:inline-block;
            width:15px;
            height:6px;
            border-top:1px solid #161616;
            border-bottom:1px solid #161616;
            cursor: pointer;
            content: " ";
        }    
    }
    .top-nav {
        position: sticky;
        top:0;
        z-index: 2;
    }
    .nav {        
        background:#fff;
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        color: #161616;        
        font-size: 0.8em;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1;
        padding:0 1rem;        
        > div {
            margin:0 auto;
            max-width: 1024px;
            margin:0 auto;
            display: flex;
            align-items: center;      
            svg {
                flex-shrink: 0;
            }      
        }
    }
    .nav-items {
        flex-grow: 1;
        text-align: right;
        margin-right:20px;
        height:70px;
    }
    .nav-section {
        position: relative;
        display:inline-flex;
        align-items: center;
        height:70px;
        pointer-events: bounding-box;
        > * {
            vertical-align: middle;
        }      
        > a > *{
            vertical-align: middle;
        }          
        a {
            display: inline-block;
            text-transform: uppercase;
            padding:0.5em;            
            transition:all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); 
            &.router-link-active {
                background-color: #C2AB64;
                color:#fff;
                svg {
                    fill:#fff;
                }
            }            
        }
        &:hover {
            .dropdown-items {
                opacity: 1;
                pointer-events: all;
            }
        }
        &:not(last-child) {
            margin-right:10px;
        }
    }
    .dropdown-items {
        position: absolute;
        top:70px;
        z-index: 1;
        left:50%;
        transform:translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition:opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); 
        a {            
            color: #161616;
            font-size: 1em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
            display:block;
            background-color: #ECECEC;
            padding:15px 0;
            width: 210px;
            text-align: center;
            &:not(:last-child) {
                border-bottom:1px solid #fff;
            }
            &:hover {
                background-color: #DCDCDC;
                color: #161616;
            }
        }
    }
    img {
        width:40px;
    }
    .bold {
         font-weight: bold; 
    }
</style>