<template>
  <section class="get-started">
            <vueVimeoPlayer
                height="100%"
                width="100%"            
                class='video'
                frameborder="0"
                :controls=false         
                :options="{
                    autoplay:true,
                    muted:true,
                    loop:true
                }"        
                video-id="521455593"
                :style="videoStyle"
                :eventsToEmit=[]
            />            
            <div class='content'>
                <img src="@/assets/type_create-our-los-angeles.svg" alt="">
                <div v-if="hasButton"><HashButton href="#impact" text="Let's Get Started" /></div>
            </div>
        </section>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import HashButton from '@/patterns/HashButton';
export default {
    name:'VideoSection',
    data: () => ({ videoStyle: {} }),    
    props: {
        hasButton:{type:Boolean, default:true}
    },
    components:{vueVimeoPlayer, HashButton},
    mounted() {
        this.adjustVideo();
        window.addEventListener("resize", this.adjustVideo);
    },
    beforeDestroy: function () {
        window.removeEventListener("resize", this.adjustVideo);
    },
    methods: {
        adjustVideo() {
        let height = Math.max(480, window.innerHeight);
        let width = Math.max(800, window.innerWidth);
        let vw, vh;
        if (width / height > 16 / 9) {
            vw = Math.round(width);
            vh = Math.round((vw * 9) / 16);
        } else {
            vh = Math.round(height);
            vw = Math.round((vh * 16) / 9);
        }
        this.videoStyle = {
            width: vw + "px",
            height: vh + "px",
            left: (width - vw) / 2 + "px",
            right: (height - vh) / 2 + "px",
        };
        },
    },
}
</script>

<style lang="scss">
    .video iframe  {
        width:100%;
        height:100%;
    }
</style>
<style lang="scss" scoped>
.holder {
    padding-bottom:125px;
    background:#161616;
}
.get-started {        
    height:calc(100vh - 70px);
    max-height: 700px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .video {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;        
    }
    img {
        margin-bottom:2em;
        max-width: calc(100vw - 2em);
    }    
    .content {
        position: relative;
        z-index: 1;
    }
    &:after {
        content: " ";
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-image: linear-gradient(135deg, #C2AB64 0%, rgba(194,171,100,0.30) 100%);
        pointer-events: none; 
    }
}
</style>
